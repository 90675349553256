<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--culture--add">
      <div class="view-header">
        <div class="view-header__footer">
          <Btn class="back-btn" round grow icon="arrow-left" :to="{ name: 'essais' }" />
          <h1 class="page-title">
            {{ essai.nom }} - Fiche bilan
          </h1>
        </div>
      </div>
    </div>

    <Tabs v-model="bilanTabs" layout="simple" v-if="helperService.userHasPermission('essaifichebilan_oedit')">

      <!-- Bilan de l'expérimentation -->
      <Tab title="Bilan de l'expérimentation">
        <p class="h2 margin">Bilan de l'expérimentation</p>
        <Section class="section--list">
          <p class="h3 margin">
            Déviations par rapport au protocole et/ou aux modes opératoires
          </p>
          <Container>
            <p class="h4">Dispositif expérimental</p>
            <Radio id="dispositif" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" inputStyle="inline" />
            <div class="margin-bottom" v-show="currentFormValues.dispositif">
              <Input id="dispositif_precisions" type="textarea" placeholder="Commentaires"
                label="Si oui, laquelle ou lesquelles et pourquoi ?" />
              <Input id="dispositif_evaluation" type="textarea" placeholder="Commentaires"
                label="Evaluation/conséquence(s)" />
            </div>
            <p class="h4">Application au champ</p>
            <Radio id="appchamp" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" inputStyle="inline" />
            <div class="margin-bottom" v-show="currentFormValues.appchamp">
              <Input id="appchamp_precisions" type="textarea" placeholder="Commentaires"
                label="Si oui, laquelle ou lesquelles et pourquoi ?" />
              <Input id="appchamp_evaluation" type="textarea" placeholder="Commentaires"
                label="Evaluation/conséquence(s)" />
            </div>
            <p class="h4">Notations et observations</p>
            <Radio id="notations" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" inputStyle="inline" />
            <div class="margin-bottom" v-show="currentFormValues.notations">
              <Input id="notations_precisions" type="textarea" placeholder="Commentaires"
                label="Si oui, laquelle ou lesquelles et pourquoi ?" />
              <Input id="notations_evaluation" type="textarea" placeholder="Commentaires"
                label="Evaluation/conséquence(s)" />
            </div>

            <p class="h4">Analyse statistique</p>
            <Radio id="stats" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" inputStyle="inline" />
            <div class="margin-bottom" v-show="currentFormValues.stats">
              <Input id="stats_precisions" type="textarea" placeholder="Commentaires"
                label="Si oui, laquelle ou lesquelles et pourquoi ?" />
              <Input id="stats_evaluation" type="textarea" placeholder="Commentaires"
                label="Evaluation/conséquence(s)" />
            </div>
          </Container>
        </Section>
        <Section class="section--list">
          <p class="h3 margin">
            Incidents pendant le cycle de la culture
          </p>
          <Container>
            <Input id="etatvegetatif" type="textarea" placeholder="Commentaires"
              label="1) Etat végétatif de la culture, sélectivité, autres : préciser" />
            <Radio id="stressclimat_temp" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" inputStyle="inline" label="2) Stress lié au climat : Température" />
            <div class="margin-bottom" v-show="currentFormValues.stressclimat_temp">
              <div class="grid margin-bottom">
                <SelectExtended v-if="helperService.getReferentialByUid('stress')" id="stress_temp_pre_id"
                  label="Pré floraison" :apiParams="{ sort: 'valeur.ASC' }" optionKey="id" optionValue="valeur"
                  :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                    'stress'
                  )}/valeur`" />
                <SelectExtended id="stress_temp_id" label="Floraison" :apiParams="{ sort: 'valeur.ASC' }" optionKey="id"
                  optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                    'stress'
                  )}/valeur`" />
                <SelectExtended id="stress_temp_post_id" label="Post floraison" :apiParams="{ sort: 'valeur.ASC' }"
                  optionKey="id" optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                    'stress'
                  )}/valeur`" />
              </div>
              <Input id="stress_temp_commentaires" type="textarea" label="Commentaires" placeholder="Commentaires" />
            </div>

            <Radio id="stressclimat_pluvio" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" inputStyle="inline" label="3) Stress lié au climat : Pluviométrie" />
            <div class="margin-bottom" v-show="currentFormValues.stressclimat_pluvio">
              <div class="grid margin-bottom">
                <SelectExtended id="stress_pluvio_pre_id" label="Pré floraison" :apiParams="{ sort: 'valeur.ASC' }"
                  optionKey="id" optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                    'stress'
                  )}/valeur`" />
                <SelectExtended id="stress_pluvio_id" label="Floraison" :apiParams="{ sort: 'valeur.ASC' }"
                  optionKey="id" optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                    'stress'
                  )}/valeur`" />
                <SelectExtended id="stress_pluvio_post_id" label="Post floraison" :apiParams="{ sort: 'valeur.ASC' }"
                  optionKey="id" optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                    'stress'
                  )}/valeur`" />
              </div>
              <Input id="stress_pluvio_commentaires" type="textarea" placeholder="Commentaires" label="Commentaires" />
            </div>
          </Container>
        </Section>

        <Section class="section--list">
          <p class="h3 margin">Rendements</p>
          <Container>
            <div class="grid">
              <Input label="CV rendement" id="cv_rendement" type="number" :options="{ min: 0, step: 0.01 }" />
              <Input label="Rendement moyen de l'essai" id="rendement_moyen" type="number"
                :options="{ min: 0, step: 0.01 }" />
            </div>
          </Container>
        </Section>
        <Section class="section--list">
          <p class="h3 margin">
            Avis sur les résultats et enseignements de l'essai
          </p>
          <Container>
            <Input id="avis_resultats" placeholder="Commentaires" type="textarea" />
          </Container>
        </Section>
      </Tab>

      <!-- Validation -->
      <Tab title="Validation" v-if="helperService.userHasPermission('essaifichevalidation_oshow')">
        <p class="h2 margin">Validation</p>
        <Section class="section--list">
          <p class="h3 margin">
            Validation statistique de l'essai
          </p>
          <Container>
            <Radio id="stat_validation" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" inputStyle="inline" label="Du point de vue statistique, validez vous cet essai?" />
            <Input id="stat_commentaire" placeholder="Commentaires" label="Commentaires" type="textarea" />
          </Container>
        </Section>

        <Section class="section--list">
          <p class="h3 margin">
            Validation agronomique de l'essai
          </p>
          <Container>
            <Radio v-if="referential['accordobjectif']" id="accord_objectif_id" :items="referential['accordobjectif']"
              inputStyle="inline" inline label="L'essai répond-il à l'objectif de départ ?" />
            <Radio id="abandone_essai" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" inputStyle="inline" inline label="Abandon de l'essai" />
            <Radio id="validation_agro" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" inputStyle="inline" inline label="Du point de vue agronomique, validez vous cet essai?" />
            <Input id="commentaires" placeholder="Commentaires" label="Commentaires" type="textarea" />
            <div class="grid">
              <Input id="validation_date" label="Date" type="date" />
              <Input id="validation_trigramme" label="Trigramme" />
            </div>
            <Radio id="cloture" :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]" inputStyle="inline" inline label="Clôturer l'essai" />
          </Container>
        </Section>
      </Tab>

      <!-- Statut de l'essai SIDEP -->
      <Tab title="Statut de l'essai SIDEP" v-if="helperService.userHasPermission('essaifichestatutsidep_oedit')">
        <Section class="section--list">
          <p class="h2 margin">Statut de l'essai SIDEP</p>

          <Section class="section--list">
            <p class="h3 margin">
              Informations de statut
            </p>
            <Container>
              <div class="grid">
                <SelectExtended id="statut_declaration_id" label="Déclaration de l’essai"
                  :apiParams="{ sort: 'valeur.ASC' }" optionKey="id" optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                    'statutsidep'
                  )}/valeur`" inline />
                <Input id="declaration_date" type="date" />
              </div>
              <div class="grid col--fullwidth">
                <SelectExtended id="statut_cloture_id" label="Clôture de l’essai" :apiParams="{ sort: 'valeur.ASC' }"
                  optionKey="id" optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                    'statutsidep'
                  )}/valeur`" inline />
                <Input id="cloture_date" type="date" />
              </div>
            </Container>
          </Section>
        </Section>
      </Tab>
    </Tabs>

    <div class="view-footer">
      <div class="view-actions view-actions--left">
        <Btn :to="{ name: 'essais' }" text="Annuler" />
        <Btn text="Enregistrer" color="primary" btnType="submit" />
      </div>
    </div>
  </form>
</template>

<script>
/* eslint-disable no-param-reassign */
import { ref } from 'vue'
import Tabs from '@/components/base/Tabs.vue'
import Tab from '@/components/base/Tab.vue'
import Section from '@/components/layout/Section.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import Container from '@/components/layout/Container.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'FicheBilanAddEditSubview',
  components: {
    Btn,
    SelectExtended,
    Container,
    Tabs,
    Tab,
    Section,
    Input,
    Radio,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const bilanTabs = ref(0)

    const validationSchema = this.yup.object().shape({
      // Bilan de l'expérimentation
      dispositif: this.yup.boolean().nullable(),
      dispositif_precisions: this.yup.string().nullable(),
      dispositif_evaluation: this.yup.string().nullable(),
      appchamp: this.yup.boolean().nullable(),
      appchamp_precisions: this.yup.string().nullable(),
      appchamp_evaluation: this.yup.string().nullable(),
      notations: this.yup.boolean().nullable(),
      notations_precisions: this.yup.string().nullable(),
      notations_evaluation: this.yup.string().nullable(),
      stats: this.yup.boolean().nullable(),
      stats_precisions: this.yup.string().nullable(),
      stats_evaluation: this.yup.string().nullable(),
      etatvegetatif: this.yup.string().nullable(),
      stressclimat_temp: this.yup.boolean().nullable(),
      stress_temp_commentaires: this.yup.string().nullable(),
      stressclimat_pluvio: this.yup.string().nullable(),
      stress_pluvio_commentaires: this.yup.string().nullable(),
      avis_resultats: this.yup.string().nullable(),
      stress_temp_pre_id: this.yup.object().nullable(),
      stress_temp_id: this.yup.object().nullable(),
      stress_temp_post_id: this.yup.object().nullable(),
      stress_pluvio_pre_id: this.yup.object().nullable(),
      stress_pluvio_id: this.yup.object().nullable(),
      stress_pluvio_post_id: this.yup.object().nullable(),

      // Validation
      cv_rendement: this.yup.string().nullable(),
      rendement_moyen: this.yup.string().nullable(),
      commentaires: this.yup.string().nullable(),
      cloture: this.yup.boolean().nullable(),
      abandone_essai: this.yup.boolean().nullable(),
      stat_validation: this.yup.boolean().nullable(),
      stat_commentaire: this.yup.string().nullable(),
      validation_agro: this.yup.boolean().nullable(),
      validation_trigramme: this.yup.string().nullable(),
      validation_date: this.yup.date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      accord_objectif_id: this.yup.object().nullable(),

      // Statut sidep
      declaration_date: this.yup.date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      cloture_date: this.yup.date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      statut_declaration_id: this.yup.object().nullable(),
      statut_cloture_id: this.yup.object().nullable(),
    })

    const { errors, isSubmitting, currentFormValues } = this.formService.initFrom(
      validationSchema,
    )

    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      currentFormValues,
      onSubmit,
      errors,
      isSubmitting,
      bilanTabs,
      method: 'post',
      endPoint: null,
      essai: {},
      bilan: {},
      validation: {},
      sidep: {},
      referentials: {},
      referential: {},
    }
  },
  emits: ['set-data', 'update:modelValue'],
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
    modelValue() {
      if (this.modelValue) {
        this.onSubmit()
      }
      this.$emit('update:modelValue', false)
    },
    bilanTabs() {
      console.log('bilanTabs', this.bilanTabs)
    },
  },
  mounted() {
    this.endPoint = 'essai'

    if (localStorage.getItem('referentials')) {
      this.referentials = JSON.parse(localStorage.getItem('referentials'))
    }

    if (this.$route.params.id) {
      this.method = 'put'
      this.endPoint += `/${this.$route.params.id}`
      this.loadData()
    }

    this.getValues('accordobjectif')
  },
  methods: {
    getValues(referentialUid) {
      this.fetchService
        .get(`dictionnaire/referentiel/${this.helperService.getReferentialByUid(
          referentialUid,
        )}/valeur`).then((response) => {
          this.referential[referentialUid] = response.data.map((referential) => ({
            label: referential.valeur,
            value: referential.id,
          }))
        })
    },

    loadData() {
      this.emitter.emit('open-loader')
      Promise.all([
        // Essai
        this.fetchService
          .get(`essai/${this.$route.params.id}`)
          .then((response) => {
            this.essai = response.data
          },
          (error) => {
            this.emitter.emit('close-loader')
            this.formService.handleApiError(error)
          }),
        // Bilan
        this.fetchService
          .get(`essai/${this.$route.params.id}/fiche/bilan`)
          .then((response) => {
            this.bilan = response.data
          },
          (error) => {
            this.emitter.emit('close-loader')
            this.formService.handleApiError(error)
          }),
        // Validation
        this.fetchService
          .get(`essai/${this.$route.params.id}/fiche/validation`)
          .then((response) => {
            this.validation = response.data
          },
          (error) => {
            this.emitter.emit('close-loader')
            this.formService.handleApiError(error)
          }),
        // Statut sidep
        this.fetchService
          .get(`essai/${this.$route.params.id}/fiche/statut_sidep`)
          .then((response) => {
            this.sidep = response.data
          },
          (error) => {
            this.emitter.emit('close-loader')
            this.formService.handleApiError(error)
          }),
      ]).finally(() => {
        const formData = { ...this.bilan[0], ...this.validation[0], ...this.sidep[0] }

        this.$emit('set-data', formData)

        const schema = this.formService.populateShema(formData)

        // Bilan
        // Température
        if (this.bilan[0].stress_temp_pre) {
          schema.stress_temp_pre_id = {
            key: this.bilan[0].stress_temp_pre.id,
            value: this.bilan[0].stress_temp_pre.valeur,
          }
        }
        if (this.bilan[0].stress_temp) {
          schema.stress_temp_id = {
            key: this.bilan[0].stress_temp.id,
            value: this.bilan[0].stress_temp.valeur,
          }
        }
        if (this.bilan[0].stress_temp_post) {
          schema.stress_temp_post_id = {
            key: this.bilan[0].stress_temp_post.id,
            value: this.bilan[0].stress_temp_post.valeur,
          }
        }
        // Pluviométrie
        if (this.bilan[0].stress_pluvio_pre) {
          schema.stress_pluvio_pre_id = {
            key: this.bilan[0].stress_pluvio_pre.id,
            value: this.bilan[0].stress_pluvio_pre.valeur,
          }
        }
        if (this.bilan[0].stress_pluvio) {
          schema.stress_pluvio_id = {
            key: this.bilan[0].stress_pluvio.id,
            value: this.bilan[0].stress_pluvio.valeur,
          }
        }
        if (this.bilan[0].stress_pluvio_post) {
          schema.stress_pluvio_post_id = {
            key: this.bilan[0].stress_pluvio_post.id,
            value: this.bilan[0].stress_pluvio_post.valeur,
          }
        }

        // Validation
        if (this.validation[0].accord_objectif) {
          schema.accord_objectif_id = this.validation[0].accord_objectif.id
        }
        if (this.validation[0].validation_date) {
          schema.validation_date = this.validation[0].validation_date
            .split(' ')
            .shift()
        }

        // Statut sidep
        if (this.sidep[0].declaration_date) {
          schema.declaration_date = this.sidep[0].declaration_date
            .split(' ')
            .shift()
        }
        if (this.sidep[0].cloture_date) {
          schema.cloture_date = this.sidep[0].cloture_date
            .split(' ')
            .shift()
        }
        if (this.sidep[0].statut_declaration) {
          schema.statut_declaration_id = {
            key: this.sidep[0].statut_declaration.id,
            value: this.sidep[0].statut_declaration.valeur,
          }
        }
        if (this.sidep[0].statut_cloture) {
          schema.statut_cloture_id = {
            key: this.sidep[0].statut_cloture.id,
            value: this.sidep[0].statut_cloture.valeur,
          }
        }

        this.formService.setFormValues(schema)

        this.emitter.emit('close-loader')
      })
    },

    handleValues(values) {
      this.emitter.emit('open-loader')

      // Bilan
      values.stress_pluvio_pre_id = values.stress_pluvio_pre_id?.key ?? null
      values.stress_pluvio_id = values.stress_pluvio_id?.key ?? null
      values.stress_pluvio_post_id = values.stress_pluvio_post_id?.key ?? null
      values.stress_temp_pre_id = values.stress_temp_pre_id?.key ?? null
      values.stress_temp_id = values.stress_temp_id?.key ?? null
      values.stress_temp_post_id = values.stress_temp_post_id?.key ?? null

      // Validation
      values.accord_objectif_id = values.accord_objectif_id ?? null
      values.validation_date = this.helperService.formatDateForApi(
        values?.validation_date,
      )

      // Statut SIDEP
      values.statut_declaration_id = values.statut_declaration_id?.key ?? null
      values.statut_cloture_id = values.statut_cloture_id?.key ?? null
      values.declaration_date = this.helperService.formatDateForApi(
        values?.declaration_date,
      )
      values.cloture_date = this.helperService.formatDateForApi(
        values?.cloture_date,
      )

      Promise.all([
        this.fetchService
          .put(
            `essai/${this.essai.id}/fiche/bilan/${this.bilan[0].id}`,
            values,
          )
          .then(
            () => { },
            (error) => {
              this.emitter.emit('close-loader')
              this.formService.handleApiError(error)
            },
          ),
        this.fetchService
          .put(
            `essai/${this.essai.id}/fiche/validation/${this.bilan[0].id}`,
            values,
          )
          .then(
            () => { },
            (error) => {
              this.emitter.emit('close-loader')
              this.formService.handleApiError(error)
            },
          ),
        this.fetchService
          .put(
            `essai/${this.essai.id}/fiche/statut_sidep/${this.bilan[0].id}`,
            values,
          )
          .then(
            () => { },
            (error) => {
              this.emitter.emit('close-loader')
              this.formService.handleApiError(error)
            },
          ),
      ]).then(() => {
        // this.loadData()
        this.emitter.emit('alert', {
          type: 'success',
          content: "L'essai a bien été mis à jour.",
        })
        this.$router.push({
          name: 'essaiFicheBilan',
          params: {
            id: this.$route.params.id,
          },
        })
        this.emitter.emit('close-loader')
      })
    },
  },
}
</script>
